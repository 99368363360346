.nav {
  width: 100%;
  padding: 1.25rem 0;
  background-color: rgba(157, 187, 223, .45);
}

.nav-list {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3rem;
}

.nav-list li:is(.btn) a {
  width: 100%;
  height: 100%;
}

.nav-list li:not(.btn) {  
  padding: .5em 1em;
  cursor: pointer;
  letter-spacing: 1px;
  margin-top: 0.5em;
}

.nav-list li:not(.btn)::before {  
  content: '';
  display: block;
  margin: auto;
  margin-bottom: .5em;
  height: 2px;
  width: 0px;
  background: transparent;
  transition: width .5s ease, background-color .5s ease;
} 

.nav-list li:not(.btn)::after {  
  content: '';
  display: block;
  margin: auto;
  margin-top: .5em;
  height: 2px;
  width: 0px;
  background: transparent;
  transition: width .5s ease, background-color .5s ease;
}

.nav-list li:not(.btn):hover::before {  
  width: 100%;
  background-color: rgba(157, 187, 223, 1);
}

.nav-list li:not(.btn):hover::after {  
  width: 100%;
  background-color: rgba(157, 187, 223, 1);
}

@media screen and (max-width: 1200px) {
  .nav {
    padding: 1.5rem 0;
  }  

  .nav-list {
    gap: 2rem;
  }
}

@media screen and (max-width: 900px) {
  .nav-list {
    gap: .25rem;
  }
}

@media screen and (max-width: 700px) {
  .nav-list {
    gap: 2em;
  }
}

@media screen and (max-width: 600px) {
  .nav-list {
    gap: .25rem;
  }
}

@media screen and (max-width: 350px) {
  .nav {
    display: none;
  }
}

