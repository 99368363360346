.hero {
  width: 100%;
  margin-top: 8rem;
  display: flex;
  justify-content: center;
}

.hero__photo {
  width: 100%;
  max-width: 750px;
  height: 525px;
}

.hero__photo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.hero__content {
  width: 100%;
  max-width: 550px; 
  height: max-content;
  padding: 2rem;
  background-color: rgba(220, 220, 220, .8);
  margin-top: 2rem;
  margin-left: -5rem;
  border-radius: 3px;
}

.hero__content h1 {
  font-size: 2rem;  
  text-transform: uppercase;
}

.hero__content p {
  margin-top: 1rem;
  line-height: 1.5;
  font-weight: 600;
  opacity: .7;
}

.hero__actions {
  flex-direction: column;  
  gap: 1.5rem;
  margin-top: 2rem;
}

.hero__actions h3 {
  font-size: 1.5rem;
  font-weight: bold;  
  text-transform: uppercase;
}

.hero__actions-btns {
  gap: 2rem;
}

.actions-mobile {
  display : none;
}

.showmore {
  outline: none;
  border: none;
  background-color: transparent;   
  font-family: inherit;  
  cursor: pointer;
  margin-left: 0;
  margin-top: .5rem;
}

.showmore span {
  text-decoration: underline;
  color: black;
  font-weight: bold;
  font-size: 1rem;
  margin-left: 0;
}

@media screen and (max-width: 1250px) {
  .hero {    
    padding-left: 3rem;    
    margin-top: 7rem;
  }

  .hero__photo {
    width: 700px;
    height: 550px;
  }

  .hero__content {
    margin: 0;
    transform: translate(-13%, 5%);
  }
}

@media screen and (max-width: 1100px) {
  .hero {
    padding-left: 2rem;    
  }

  .hero__photo {
    min-width: 600px;
    transform: translateX(5%);
  } 
}

@media screen and (max-width: 1000px) {
  .hero {
    padding-left: 2rem;
  }

  .hero__photo {
    min-width: 500px;
    height: 450px;
    margin-left: 1.5rem;
    transform: translateX(0%);  
  }

  .hero__content {
    padding: 1rem;
    width: 600px; 
  }
}


@media screen and (max-width: 900px) {
  .hero {
    display: block;
    margin: 0;
    padding-left: 0;    
  }

  .hero__photo {
    width: 900px;
    max-width: 100%;
    height: 500px;
    margin-left: 0;
  }

  .hero__content {
    margin: 0 auto;
    max-width: 650px;
    text-align: center; 
    transform: translate(0,-30%);
    padding-top: 2em;
    padding-bottom: 2em;
  }
}

@media screen and (max-width: 650px) { 
  .hero__photo {
    height: 400px;
  }

  .hero__content {   
    width: 100%;
    max-width: 650px; 
    opacity: .9;
    transform: translateY(0);
  }
}

@media screen and (max-width: 450px) {
  .hero__photo img {
    object-position: 80% center;
  }
}

@media screen and (max-width: 400px) {
  .hero__photo img {
    object-position: 100% center;
  }

  .hero__actions-btns {
    flex-direction: column;
  }
}

@media screen and (max-width: 350px) {
  .hero__photo img {
   object-position: right center;
  }

  .hero__photo {
    height: 300px;
  }

  .hero__content {
    padding: .5;
  }
}