.contact {
  width: 100%;
  margin-top: 6rem;
  margin-bottom: 4rem;  
  padding: 6rem 0;
}

.contact h2 {
  font-size: 4rem;
  background-color: #f8f8ff; 
  transform: translateY(50%);
  display: inline-block;
  font-weight: 500;
  padding: 0 3rem;
  position: absolute;
  top: -6%;  
  left: 50%;
  transform: translateX(-50%);
  width: 450px;
}

.contact .contact-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
}

.contact .form-wrapper {
  border: 6px solid rgba(157, 187, 223, .3);  
  padding: 5rem 11rem 5rem 4rem;  
  width: 100%;
  max-width: 800px;  
  transform: translateX(7%);
}

.contact .contact-wrapper .contact-image {
  width: 550px;
  height: 500px;
  overflow: hidden;
  transform: translateX(-7%);  
  padding: 2rem 0;
  background-color: #f8f8ff; 
}

.contact .contact-wrapper .contact-image img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4rem;
}

.row > .form-group {
  flex: 1;
  height: 111px;
}


.form-group {
  display: flex;
  flex-direction: column;
}

span, .error {
  color: tomato;
}

.error {
  margin-top: 0.5em;
  font-size: 0.75rem;
}

span {
  margin-left: 0.25em;
  font-size: 0.75rem;
}

label {
  font-size: 0.9rem;
  opacity: .5;
  margin-top: 2rem;
}

.col {
  margin-top: 2rem;
}

input, textarea {
  margin-top: 0.5rem;
  outline: none;
  border: none;
  width: 100%;  
  background-color: transparent;
  border-bottom: 1px solid rgba(0, 0, 0, .5);
  padding: .25rem;
  font-size: 1rem;
  font-family: inherit;
  letter-spacing: 1px;
}

.contact .contact-wrapper .form-wrapper form button {
  margin-top: 2rem;
  padding: 1.5em 2em;
  width: fit-content;
  cursor: pointer;
}

@media screen and (max-width: 1300px) {
  .contact .contact-wrapper .contact-image {
    width: 800px;
    height: 450px;
    transform: translateX(-8%);  
  }
}

@media screen and (max-width: 1200px) {
  .contact {
    margin-top: 5rem;
  }

  
  .contact .contact-wrapper .contact-image {
    width: 1000px;
    height: 450px;
    transform: translateX(-10%);  
  }

  .contact .form-wrapper {
    padding-right: 10rem;
    padding-left: 1.5rem;
  }
}

@media screen and (max-width: 1100px) {
  .contact .contact-wrapper .contact-image {
    width: 1000px;
    height: 450px;
    transform: translateX(-18%);  
  }  
}

@media screen and (max-width: 1000px) {
  .contact {
    margin-top: 1rem;
    margin-bottom: 2rem;
  }

  .contact .form-wrapper  {   
    transform: translateX(0);
    max-width: 100%;
    margin-left: 1rem;
    margin-right: 1rem;
    display: flex;
    width: 100%;
  }  

  .contact .form-wrapper form {
    width: 100%;    
  } 

  .row {
    flex: 1;
    gap: 0;
  }

  .contact h2 {
    top : -5%;
    left: 50%;
    transform: translateX(-50%);
    width: 450px;
  }

  .contact .contact-wrapper .contact-image {   
    display: none;
  }   

  .contact .form-wrapper .form-group {
    margin-left: 7rem;
  }
  
  .contact .contact-wrapper .form-wrapper form button {
    margin-left: 7rem;
  }
}

@media screen and (max-width: 800px) {
  .row {
    flex-direction: column;
    gap: 2rem
  }
  
  .contact .form-wrapper .form-group {
    width: 100%;
    margin-left: 4rem;
  }
  
  .row > .form-group {
    margin-left: 8rem;
  }

  .contact .contact-wrapper .form-wrapper form button {
    margin-left: 4rem;
    width: 100%;
    padding: 2rem;
    margin-top: 3rem;
  }
}

@media screen and (max-width: 800px) {
  .contact {
    padding-left: 0;
    padding-bottom: 0;
    margin-bottom: 0;
  }

  .contact .contact-wrapper .form-wrapper {
    padding-right: 1.5rem;
    border-left: none;
    border-right: none;
    border-bottom: none;
  }

  .row {
    margin-left: 0;
  }
  
  .row > .form-group {
    margin-left: 0;
    width: 100%;
    flex: 1;
  }

  .contact .form-wrapper {
    margin-left: 0;
    margin-right: 0;
  }
  
  .contact .form-wrapper .form-group {
    width: 100%;
    margin-left: 0;
  }

  .contact .contact-wrapper .form-wrapper form button{
    margin-left: 0;
  }
}

@media screen and (max-width: 700px) {
  .contact {
    margin-top: 0;
    padding-top: 2rem;
  }
}

@media screen and (max-width: 500px) {
  .contact  h2{
    padding-left: 0;
    padding-right: 0;
    text-align: center;   
    width: 400px; 
    font-size: 2.5rem;  width: max-content;
    top: -3%;
    padding: 0 .5em;
    
  }
}

@media screen and (max-width: 400px) {
  .contact {
    padding-bottom: 1rem;
  }

  .contact .form-wrapper {
    padding-bottom: 3rem;
  }
}