.reserve {
  margin: 8rem 0 0 0;
  /* border: 6px solid rgba(157, 187, 223, .3); */
  text-align: center;
  /* padding-bottom: 4.5rem; */
  padding: 4rem 0 5rem;
  border-radius: 3px; 
  margin-bottom: 4rem;
  background-color: rgba(157, 187, 223, .45);
}

.reserve h2{
  font-family: "Poppins", sans-serif;
  font-size: 4rem;
  /* background-color: #f8f8ff; */
  /* transform: translateY(-50%); */
  /* padding: 0 3rem; */
  /* display: inline-block; */
  font-weight: 500;
}

.reserve .actions {
  gap: 4rem;
  margin-top: 5rem;
}

.reserve .actions button {
  padding: 2em 2.5em;
  width: 250px;
}



@media screen and (max-width: 700px) {
  .reserve {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
  }
  
  .reserve h2 {
    font-size: 3.5rem;
    padding: 0 1rem;
  }
}

@media screen and (max-width: 600px) {
  .reserve {
    padding: 2rem;
  }

  .reserve h2 {
    font-size: 3rem;    
  }

  .reserve .actions {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }  
}


@media screen and (max-width: 500px) {
  .reserve {
    border-left: none;
    border-right: none;
  }
  
  .reserve h2 {
    font-size: 2.5rem;    
  }

  .reserve .actions {
    flex-direction: column;
    margin-top: 3rem;
    gap: 1.5rem;
  }

  .reserve .actions button {
    width: 100%;
  }
}

@media screen and (max-width: 300px) {
  .reserve h2 {
    font-size: 1.5rem;  
  }
}