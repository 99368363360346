/* Resets */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  width: 100vw;
  height: 100vh; 
  overflow-x: hidden;
  font-family: 'Work Sans', sans-serif;
  background-color: #f8f8ff;
  font-size: 100%;
}

a, a:visited {
  text-decoration: none;
  color: inherit;
}

ul {
  list-style: none;
}

/* Global Classes */
.container {
  width: 100%;
  max-width: 1500px;
  margin: 0 auto; 
}

.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.heading {
  font-family: 'Poppins', sans-serif;;
}

.btn {  
  padding: 1em;  
  width: 175px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 2.5px;
  font-weight: 600;
  color: #000;  
  border: none; 
  border-radius: 0px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
  border-radius: 3px;
}

.btn a {
  width: 100%;
  height: 100%;
}

.btn-primary {
  background-color: rgba(157, 187, 223, .8);
}

.btn-secondary {
  /* background-color: rgb(154, 154, 154, .5); */
  background-color: rgba(157, 187, 223, .8); 
}

.btn:hover {
  background-color: rgba(157, 187, 223, 1);
  box-shadow: 0px 15px 20px rgba(157, 187, 223, .8);
  color: #fff;
  transform: translateY(-7px);
}

@media screen and (max-width: 700px) {
  .btn-nav {
    display: none;
  }  
}

@media screen and (max-width: 400px) {
  .btn {
    width: 250px;
  }
}





/*
  1200 - desktop
  900 - tablet
  600 - mobile
*/
