.modal_overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(0, 0, 0, 1);
  opacity: .5;
  z-index: 1000;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #f8f8ff;
  z-index: 1000;  
  color: black;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  width: 100%;
  max-height: 100vh;
  overflow-y: auto;
  max-width: 900px;
  padding: 2rem 1rem;    
  border-radius: 3px;
}

.modal .header {
  text-align: right;
  font-size: 1.5rem;  
}

.modal .header .close {
  cursor: pointer;
}

.header_title h5 {
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 1rem;
  margin-top: 2rem;
}

.modal p {
  margin-bottom: 1rem;
  line-height: 1.5;
}

.space h5 {
  font-size: 1.25rem;
  margin-top: 2rem;
}

.modal .btn-close {
  min-width: 100%;
  height: 4rem;
  margin-top: 1rem;
  font-weight: bold;
  font-size: 1.25rem;
}

@media screen and (max-width: 350px) {
  .space h5 {
    margin-bottom: -1.5rem;
  }
}