footer {
  background-color: rgba(157, 187, 223, .45);
}

.footer-wrapper {
  display: flex;
  justify-content: center;
  align-content: center;
  gap: 5rem;
  padding-bottom: 2rem;
}

@media screen and (max-width: 500px) {
  .footer-wrapper {
    flex-direction: column;
    gap: 1rem;
    text-align: center;
  }
}

@media screen and (max-width: 350px) {
  p {
    margin-top: 2rem;
  }
}