.card {
  position: relative;
  width: 400px;
  height: 400px;
  overflow: hidden;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23) ;
  background-repeat: 15px;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
}

.card:first-child .card-image img{
 object-position: 30% center;
}

.card .card-image {
  width: 100%;
  height: 100%;
}

.card .card-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.card .card-content {
  position: absolute;
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  flex-direction: column;
  backdrop-filter: blur(15px);
  bottom: -400px;
  box-shadow: 0 -10px 10px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  transition: bottom 0.5s;
  transition-delay: 0.1s;
}

.card:hover .card-content {
  bottom: 0;
}

.card .card-content .title {
  font-family: "Poppins", sans-serif;
  text-transform: uppercase;
  color: white;
  letter-spacing: 2px;
  font-weight: bold;
  font-size: 1.5rem;
  text-align: center;
  margin: 20px 0 15px;
  line-height: 1.1em;
  transition: 0.5s;
  transform: translateY(-20px);
  transition-delay: 0.6s;
  opacity: 0;
}

.card:hover .card-content .title {
  opacity: 1;
  transform: translateY(0px);
}

.card .card-content .list {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-direction: column;
  bottom: 10px;
  margin-top: 16px;
}

.card .card-content .list li {
  list-style: none;
  margin: 10px;
  transform: translateY(40px);
  transition: 0.5s;
  opacity: 0;
  color: white;
}

.card:hover .card-content .list li {
  transform: translateY(0px);
  opacity: 1;
}

.mobile {
  display: none;
}

@media screen and (max-width: 1300px) {
  .card {
    width: 350px;
    height: 350px;
  }

  .card .card-content {
    height: 350px;
  }
}

@media screen and (max-width: 1200px) {
  .card {
    width: 300px;
    height: 300px;
  }

  .card .card-content {
    height: 350px;
  }
}

@media screen and (max-width: 1000px) {
  .card {
    width: 275px;
    height: 275px;
  }

  .card .card-content {
    height: 275px;
    bottom: -275px;
  }
}

@media screen and (max-width: 900px) {
  .card {
    display: none;
  }

  .mobile {  
    display: block;
    width: 90%;
    max-width: 450px;
    height: 450px;
    margin: 0 auto;
    padding-bottom: 1rem;
  }
  
  .mobile .card-mobile {
    width: 100%;    
    height: 100%;
    overflow: hidden;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23) ;
  }

  .mobile .card-mobile .card-image-mobile {
    width: 100%;
    height: 250px;
  }

  .mobile .card-mobile .card-image-mobile img{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .mobile .card-content-mobile {
    flex-direction: column;
    justify-content: space-evenly;
    height: 200px;
  }

  .mobile:nth-child(2) .card-mobile .card-image-mobile img {
    object-position: center 95%;
  }

  .mobile .card-mobile .card-content-mobile .title {
    font-size: 1.25rem;
  }

  .mobile .card-mobile .card-content-mobile .list-mobile li {
    margin-bottom: 0.75em;
  }

  .mobile .card-mobile .card-content-mobile .list-mobile {
    flex-direction: column;
  }
}

@media screen and (max-width: 400px) {
  .mobile {
    width: 90%;
    height: 425px
  }

  .mobile .card-mobile .card-image-mobile {
    height: 200px;
  }
}


